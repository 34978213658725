var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Tagging",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "div",
        [
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              "To allow input that's not present within the options, set the taggable prop to true. If you want new tags to be pushed to the options list, set push-tags to true"
            ),
          ]),
          _c("br"),
          _c("h6", { staticClass: "mb-2" }, [_vm._v("Vue Select - Taggable")]),
          _c("v-select", {
            attrs: { taggable: "", dir: _vm.$vs.rtl ? "rtl" : "ltr" },
          }),
          _c("br"),
          _c("h6", { staticClass: "mb-2" }, [
            _vm._v("Vue Select - Taggable & Push-Tags"),
          ]),
          _c("v-select", {
            attrs: {
              taggable: "",
              "push-tags": "",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
          }),
          _c("small", { staticClass: "muted" }, [
            _vm._v(
              "Try adding a tag and removing it. It'll still be present within the options list."
            ),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <h6>Vue Select - Taggable</h6>\n  <v-select taggable :dir=\"$vs.rtl ? 'rtl' : 'ltr'\" /><br>\n\n  <h6>Vue Select - Taggable &amp; Push-Tags</h6>\n  <v-select taggable push-tags :dir=\"$vs.rtl ? 'rtl' : 'ltr'\" />\n</template>\n\n<script>\nimport vSelect from 'vue-select'\n\nexport default{\n  data() {\n    return {\n      options: []\n    }\n  },\n  components: {\n    'v-select': vSelect,\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }