var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { attrs: { title: "Selecting Values" } }, [
    _c(
      "div",
      [
        _c("selecting-values-default"),
        _c("selecting-values-single-multiple-selection"),
        _c("selecting-values-tagging"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }