var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { attrs: { title: "Dropdown Options" } }, [
    _c("p", [
      _vm._v(
        "vue-select accepts arrays of strings or objects to use as options through the "
      ),
      _c("code", [_vm._v("options")]),
      _vm._v(" prop"),
    ]),
    _c(
      "div",
      { staticClass: "mt-5" },
      [_c("dropdown-options-basic"), _c("dropdown-options-option-labels")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }