var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Default",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "div",
        [
          _c("p", { staticClass: "mb-3" }, [
            _vm._v(
              "The most common use case for vue-select is to have the chosen value synced with a parent component. vue-select takes advantage of the v-model syntax to sync values with a parent"
            ),
          ]),
          _c("v-select", {
            attrs: { options: _vm.options, dir: _vm.$vs.rtl ? "rtl" : "ltr" },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _c("br"),
          _c("p", [
            _vm._v(
              "If you don't require the value to be synced, you can also pass the prop directly:"
            ),
          ]),
          _c("prism", { staticClass: "rounded-lg" }, [
            _vm._v('\n<v-select :value="selected"></v-select>\n            '),
          ]),
          _c("p", { staticClass: "mt-5" }, [
            _vm._v(
              "This method allows you to pre-select a value(s), without syncing any changes to the parent component. This is also very useful when using a state management tool, like Vuex"
            ),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <v-select v-model=\"selected\" :options=\"options\" :dir=\"$vs.rtl ? 'rtl' : 'ltr'\" /><br>\n</template>\n\n<script>\nimport vSelect from 'vue-select'\n\nexport default{\n  data() {\n    return {\n      options: [\n        {id: 1, label: 'foo'},\n        {id: 3, label: 'bar'},\n        {id: 2, label: 'baz'},\n      ],\n      selected: {id: 3, label: 'bar'},\n    }\n  },\n  components: {\n    'v-select': vSelect,\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }