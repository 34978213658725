var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Basic",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c(
        "div",
        [
          _c("v-select", {
            attrs: {
              options: ["foo", "bar"],
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
          }),
          _c("p", { staticClass: "my-5" }, [
            _vm._v("When provided an array of objects, "),
            _c("code", [_vm._v("vue-select")]),
            _vm._v(" will display a single value of the object. By default, "),
            _c("code", [_vm._v("vue-select")]),
            _vm._v(" will look for a key named "),
            _c("code", [_vm._v("label")]),
            _vm._v(" on the object to use as display text"),
          ]),
          _c("v-select", {
            attrs: {
              options: [{ label: "Foo", value: "foo" }],
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <v-select :options=\"['foo','bar']\" :dir=\"$vs.rtl ? 'rtl' : 'ltr'\" />\n  <v-select :options=\"[{label: 'Foo', value: 'foo'}]\" :dir=\"$vs.rtl ? 'rtl' : 'ltr'\" />\n</template>\n\n<script>\nimport vSelect from 'vue-select'\n\nexport default{\n  components: {\n    'v-select': vSelect,\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }