var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-select-demo" } },
    [_vm._m(0), _c("select-dropdown-options"), _c("select-selecting-values")],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "http://sagalbot.github.io/vue-select/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("vue-select")]
      ),
      _vm._v(
        " is a native Vue.js select component that provides similar functionality to Select2. Read full documnetation "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "http://sagalbot.github.io/vue-select/docs/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }